.main-menu {
	display: flex;
	justify-content: space-between;
	width: 100%;

	&:hover,
	&:focus-within {
		.nav-link::after {
			content: none;
		}
	}

	.nav-link {
		background-color: transparent;
		color: $charcoal;
		font-family: $font-gotham-round-book;
		padding-bottom: 1rem;
		padding-top: 1rem;
		text-transform: uppercase;
		transition: all ease 0.5s;
		position: relative;

		&.active-link::after,
		&:hover::after,
		&:focus::after {
			content: '';
			position: absolute;
			left: 4px;
			bottom: 14px;
			width: 100%;
			height: 4px;
			background-color: #0056A4;
			transform-origin: top;
			transform: skewX(-50deg);
		}
		&.active-link::before,
		&:hover::before,
		&:focus::before {
			content: '';
			position: absolute;
			left: -2px;
			bottom: 14px;
			width: 50%;
			height: 4px;
			background-color: #0056A4;
		}

	}

	.nav-btn {
		background-color: darken( $primary, 15% );
		border: 0;
		display: flex;
		justify-content: center;
		width: fit-content;
		padding: 5px 20px;
		&:hover {
			background-color: darken( $primary, 30% );
		}
	}
}

.main-menu-modal {
	background-color: $primary;
	font-size: 1.6rem;
	height: 100vh;
	width: 100vw;

	.nav-item {
		display: block;
		width: 100%;
	}

	.nav-link {
		background-color: transparent;
		color: $white;
		padding-bottom: 0.5rem;
		padding-top: 0.5rem;
		text-transform: uppercase;
		transition: all ease 0.5s;

		&:hover {
			background-color: darken( $primary, 10% );
		}
	}

	.main-menu {

		.nav-link {
			font-family: $f-roboto-black;
		}
	}

	.form-control {
		padding: 1.5rem;
	}

	hr {
		background-color: $white;
		width: 15%;
	}

	.close {
		opacity: 1;
		position: absolute;
		right: 1rem;
		top: 1rem;
		z-index: 10;
	}
}
.btn[target=_blank] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-right: 50px;

  &:after {
    content: '';
    display: block;
    background: url("../../dist/img/icons/external-link-blue.svg");
    background-size: cover;
    width: 25px;
    height: 25px;
    margin-left: 10px;
  }

  &:hover, &:focus {
    &:after {
      background: url("../../dist/img/icons/external-link-white.svg");
    }
  }

  &:not([class*='outline']) {
    &:after {
      background: url("../../dist/img/icons/external-link-white.svg");
    }
  }
}

.icon-button {
  box-shadow: none;
  transition: all .3s;

  &:hover, &:focus {
    box-shadow: 0 0 20px $gray-500;
    transition: all .3s;
    text-decoration: none;
  }
}

.container-thumbnails {
  text-align: center;
}

.img-thumbnail-link {
  display: inline-block;
  padding: 5px;
}

.img-border {
  border-color: $gray-500;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  opacity: 0.57%;
}

.table-political {
  margin-bottom: 3rem;

  td {
    border: 1px solid $gray-700;
    padding: .5rem;
    vertical-align: top;
  }
}
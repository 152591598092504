.accordion--standalone {
    background-color: white;
    color: #000;
    cursor: pointer;
    padding: 40px 20px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    border-bottom: solid 1px #999;
    position: relative;

    h2 {
        margin: 0;
        font-size: 22px;
    }
}

.active-faq {
    border-bottom: none;
}

.accordion--text {
    padding: 20px 20px 40px 20px;
    display: none;
    overflow: hidden;
    border-bottom: solid 1px #999;
    background-color: white;
}

.accordion--text p {
    margin-top: 0;
}



.accordion--text h3, .accordion--text-preview h3 {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
}


.accordion--standalone::after {
    background: url("../img/icons/arrow-right-black.svg") no-repeat center center;
    content: '';
    height: 24px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    transition: all 0.5s ease-in-out;
    width: 2.2rem;
}

.accordion--standalone.active{ padding-right:0;}


.accordion--standalone.active h3{padding-top:0;}

.accordion--standalone.active::after {
    background: none;
}

.active-faq::after {
    transform: translateY(-50%) rotate(-90deg);
}
.old-contests-container {
    display: inline-block;
}

.contest-container {
    z-index: 2;
    position: relative;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: stretch;
    flex-direction: row;

    @include media-breakpoint-down(md) {
        flex-direction: column;

    }
    .red-corner {
        position: absolute;
        left: 0px;

        @media(max-width: 1092px) {
            display: none;
        }
    }

    div {
        width: 50%;
        display: inline-block;
        overflow: hidden;
    }

    .contest-img {
        height: 100%;
        position: relative;
        left: 50%;
        transform: translate(-50%);
        @include media-breakpoint-down(md) {
            width: 100%;
            height: auto;

        }
    }

    .contest-right {
        float: right;
        background-color: white;
        padding: 32px;
        font-size: 14px;
        font-family: $f-roboto-regular;

        h2 {
            font-size: 28px;
        }

        p {
            color: #646464;
        }

        .contest-description {
            font-size: 16px;
        }

        a.btn {
            font-size: 15px;
            padding: 10px 24px;
        }
    }
}

.contest-banner__wrapper {
    margin-top: -25px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @include media-breakpoint-up(md) {
        margin-top: -45px;
    }
}

@include media-breakpoint-down(md) {
    .contest-banner__wrapper .contest-container {

        div {
            width: 100%;
        }

        .contest-left {
            img {
                border-radius: 8px 8px 0 0;
            }
        }


        .contest-right {
            border-radius: 0 0 8px 8px;
           /* height: 350px;*/
        }
    }
}
.site-main-navigation {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;

    @include media-breakpoint-up(lg){
        flex-wrap: nowrap;
        align-items: center;
    }

    .navbar-toggler {
        display: block;
        position: absolute;
        left: 16px;
        top: 19px;
        font-size: 22px;
        padding: 0;
        border: 0;

        @include media-breakpoint-up(lg){
            display: none;
        }
    }

    .header__logo {
        picture {
            img {
                width: auto;
                max-height: 66px;
                margin-bottom: -16px;
                @include media-breakpoint-up(sm){
                    max-height: 56px;
                    margin-bottom: 0;
                }
                @include media-breakpoint-up(lg){
                    max-height: 96px;
                }
            }
        }
    }

    .navbar {
        display: none;
        @include media-breakpoint-up(lg){
            display: block;
            flex-shrinK: 0;
            padding: 0 20px;
            margin: 0;
        }

        .navbar-nav {
            @include media-breakpoint-up(lg){
                display: flex;
                align-items: center;
                flex-direction: row;
                gap: 32px;
            }
        }

        a {
            white-space: nowrap;
            font-size: 20px;
        }
    }

    .form-search {
        display: block;
        flex-grow: 1;
        position: relative;

        button {
            position: absolute;
            right: 0;
            top: 0;
            height: 3rem;
            width: 3.5rem;
            background-color: #0055a5;
            border-bottom-right-radius: 32px;
            border-top-right-radius: 32px;
            margin: 16px 0;

            @include media-breakpoint-up(md) {
                margin: 0;
            }

            img {
                max-width: 100%;
                padding-right: 5px;
            }
            &:hover {

            }
        }

        input {
            padding: 14px 64px 14px 24px;
            font-size: 14px;
            line-height: 1;
            background-color: #E9F1F8;
            border: 1px solid #004380;
            color: #333333;
            width: 100%;

            &::placeholder {
                color: #004380;
            }
        }
    }
}

/*
.site-main-navigation {

	.navbar-toggler {
		border: 0;

		span {
			align-items: center;
			display: flex;
			font-size: 1rem;
			text-transform: uppercase;
		}

		i {
			font-size: 1.5em;
			margin-left: 0.5rem;
		}
	}

	.smn__list {
		flex-grow: 1;

		.smn__list__link {
			color: rgba( $white, 1 );
			font-size: 1.1rem;
			text-transform: uppercase;
		}
	}

	.smn__list--second {

		.smn__list__link--second {
			color: rgba( $white, 1 );
			font-size: 1rem;
		}
	}

	hr {
		width: 100%;
	}
}*/

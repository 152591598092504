/*
Content gallery
*/

.content-gallery {
	position: relative;
	top: -260px;
}

.content-gallery__inner {

	@include media-breakpoint-down(sm) {
		max-width: inherit;
		padding: 0;
		width: 100vw;
	}
}

.cg__featured {
	background: url( /dist/img/gallery-item-1-bg.jpg ) no-repeat center/cover;
	min-height: 610px;
	padding-bottom: 100px;
	position: relative;
	width: 100%;

	@include media-breakpoint-up(md) {
		padding-bottom: 0;
	}
}

.cg__featured__image {
	position: relative;
	transform: translateY( 50% );
	z-index: 1;

	img {
		z-index: 1;
	}
}

.cg__featured__content {
	margin: 0 auto;
	position: relative;
	text-align: center;
	width: 90%;
	z-index: 0;

	@include media-breakpoint-up(md) {
		width: 70%;
	}
}

.cg__content {
	background: rgba( 255, 255, 255, 0.9 );
	box-shadow: 0 0 0 14px rgba( 209, 224, 229, 0.9 );
	padding: 80px 40px 40px 40px;
	z-index: 0;

	.btn {
		margin-top: 10px;
	}
}

.cg__title {
	font-family: $f-domine-bold;
	font-size: 26px;
	margin: 20px auto;
}

.cg__body p {
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 1.2px;
	line-height: 24px;
}

.cg__list {
	list-style: none;
	padding-left: 0;
}

.cg__list__item {
	margin-top: 20px;
	padding-bottom: 40px;
	width: 48%;

	@include media-breakpoint-up(md) {
		display: block;
		margin-top: 0;
		width: 100%;
	}

	.card {
		display: block;
		padding: 10px;
		text-decoration: none;

		@include media-breakpoint-up(md) {
			padding: 0;
		}
	}

	.card__title {
		color: $Allports;
		font-family: $f-domine-bold;
		font-size: 20px;
		margin-top: 0;
	}

	.card__category {
		color: black;
		font-size: 14px;
		margin-top: 20px;
		text-transform: uppercase;

		a:hover & {
			color: inherit;
		}
	}
}

.card.card--recipe {
	border: 0;
}
.about-banner {
  margin-top: -20px;
  position: relative;
  z-index: 2;
  box-shadow: 0 5px 10px #16161514;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  flex-direction: row;

  @include media-breakpoint-up(md) {
    margin-top: -38px;
  }

  div{
    width: 50%;
  }

  .about-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .about-right {
    padding:32px;
    font-size: 14px;
    font-family: $f-roboto-regular;

    h2{
      font-size: 28px;
    }

    .about-description{
      font-size: 16px;
      color: #646464;
    }
  }
}

@include media-breakpoint-down(md) {
  .about-banner {
    flex-direction: column;

    div {
      width: 100%;
      .about-img {
        max-height: 60vw;
      }
    }
  }
}
.deco-header-section {
    h1,
    h2 {
        display: inline-block;
        position: relative;
        color: white;
        padding: 22px 0 32px 0;

        @include media-breakpoint-up(md) {
            padding: 32px 0 64px 0;
        }

        &::after {
            content: '';
            background-color: #0056A4;
            transform-origin: top;
            transform: skewX(-50deg);
            position: absolute;
            top: 0;
            bottom: 0;
            right: -145px;
            left: -50vw;
            @include media-breakpoint-up(md) {
                right: -175px;
            }
        }



        span {
            position: relative;
            z-index: 2;
            font-family: $font-gotham-round-book;

            strong {
                font-family: $font-gotham-round-bold;

                @include media-breakpoint-down(md) {
                    display: block;
                }
            }
        }
    }

    &.deco-no-image{
        h1{
            padding: 22px 0 22px 0;
            margin-bottom: 32px;

            @include media-breakpoint-up(md) {
                padding: 32px 0 32px 0;
            }
        }
    }

    &.deco-header--reverse {
        text-align: right;

        h1,
        h2 {
            &::after {
                left: -15px;
                right: -50vw;
                @include media-breakpoint-down(md) {
                    left: -65px;
                    right: -50vw;
                }
            }
        }
    }

    &.deco-header-main {
        h1 {
            font-size: 38px;

        }
    }

    &.deco-blue {
        h1,
        h2 {
            &::after {
                background-color: $primary;
            }
        }
    }
    &.deco-red {
        h1,
        h2 {
            &::after {
                background-color: $Monza;
            }
        }
    }
    &.deco-orange {
        h1,
        h2 {
            &::after {
                background-color: $Trinidad;
            }
        }
    }
}

.page_deco-container {
    display: none;
    @include media-breakpoint-up(xl) {
        display: block;
    }

    .page_deco-1 {
        position: absolute;
        right: 0;
        top: 120px;
    }
    .page_deco-2 {
        position: absolute;
        bottom: -115px;
        left: -200px;
    }
    .page_deco-3 {
        position: absolute;
        left: 0;
        top: 30%;
    }
}
.form-search {

	input {
		background-color: #f8f8f8;
		border: 0;
		border-radius: 2rem;
		color: white;
		margin: 16px 0;

		@include media-breakpoint-up(md) {
			margin: 0;
		}

	}

	.btn {
		background-color: darken( $primary, 15% );
		border: 0;
		display: flex;
		justify-content: center;

		&:hover {
			background-color: darken( $primary, 30% );
		}

	}
}
.input-group{
	input{
		z-index: 0!important;
	}
	button {
		position: absolute;
		right: 0;
		top: 0;
		height: 2.4rem;
		width: 3rem;
		background-color: #0055a5;
		border-bottom-right-radius: 32px;
		border-top-right-radius: 32px;


		img {
			max-width: 100%;
			padding-right: 5px;
		}

	}
}

#side_bar{
	max-width: 100%;
}
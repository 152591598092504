
/*
    Page header
*/

.page__header {
	height: 70vh;
	max-height: 600px;
	min-height: 600px;
	padding-bottom: 40px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	@include media-breakpoint-down(md) {
		transform: translateY(20px);
	}

	@include media-breakpoint-down(sm) {
		height: 150px;
		margin-top: -21px;
		min-height: 300px;
		padding-bottom: 40px;
		position: relative;
	}

	@media (max-width: 375px) {

		.page__header {
		max-height: 466px;
		min-height: 437px;
		}

	}


	.custom-hp-btn {
	    position: absolute;
        bottom: 0;
        margin-bottom: 129px;
        box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.2);
        left: calc(50% - 100px);

        @include media-breakpoint-down(sm) {
        		margin-bottom: 99px;
        	}
	}

}

.complice-image {
	margin-right: 66%; 
    /* mettre margin-right à 0 pour centrer */
	margin-top: -7rem;
	width: 40rem;

	@include media-breakpoint-down(lg) {
		margin-right: 54%;
        /* mettre margin-right à 0 pour centrer */
		margin-top: -8rem;
		width: 66rem;
	}

	@include media-breakpoint-down(md) {
		margin-right: 45%;
        /* mettre margin-right à 0 pour centrer */
		margin-top: -6rem;
		width: 20rem;
	}

	@include media-breakpoint-down(sm) {
		margin-right: 0;
        /* mettre margin-right à 0 pour centrer */
		margin-top: 1rem;
		width: 20rem;
	}
  }

@include media-breakpoint-up(md) {

	.page__header {
		padding-bottom: 0;
		margin-bottom: -100px;
		min-height: 370px;
	}
}

/*
    Pge header heading block
*/

.page__header__heading-block {
	background: rgba( 255, 255, 255, 0.9 );
	box-shadow: 0 0 0 14px rgba( 209, 224, 229, 0.9 );
	left: 50%;
	margin: 0 auto;
	max-width: 500px;
	padding: 40px;
	position: absolute;
	text-align: center;
	top: 80px;
	transform: translateX( -50% );
	width: 90%;
}

.page__header__heading-block__title {
	font-family: $f-domine-bold;
	font-size: 2.5rem;
	margin-bottom: 1rem;
}
/*
* Stores page
*/

.store-locator-container {
	margin-top: -20px;
	z-index: 2;
	border-radius: 8px;
	overflow: hidden;

	@include media-breakpoint-up(md) {
		margin-top: -38px;
	}

	.container-top-map {
		padding: 32px;
		margin-bottom: 12px;

		a {
			color: $Trinidad;
		}
	}
}

#address{
	border-radius: 24px;
	background-color: #E9F1F8;
	border: 1.5px solid #004380;
	color: #004380;
	padding-right: 3.5rem;
}
.stores {

	& > .row {
		margin-right: 0;
		margin-left: 0;

		h1 {
			margin-bottom: 0;
		}
	}
	 .container > div, &.container > div {
		&:not(:first-child) {
			background-color: $white;
		}
	}

	.s__map {

		td {
			border-top: 0;
		}
	}

	.s__directions {
		width: 459px;
		margin: auto;
		@media (min-width: 992px){
			margin-left: auto;
			margin-right: 0;
		}

		div {
			margin: 0 auto;
		}
	}

	.table-striped{
		thead tr{
			background-color: $Trinidad;

			th {
				font: normal normal bold 22px $font-gotham-round-bold;
			}
		}
		tbody {
			background-color: $white;

			tr td {
				font: normal normal normal 16px $font-franklin-gothic-book-normal;
				line-height: 24px;

			}

			.store__phone {
				white-space: nowrap;
				color: $Trinidad;
				font: normal normal normal 16px $font-franklin-gothic-book-normal;
				line-height: 24px;


			}
			.list-store-name {
				font: normal normal normal 16px $font-franklin-gothic-demi-normal;
				line-height: 24px;

			}
		}
	}
}

.title-store {
	font: normal normal bold 24px $font-gotham-round-bold !important;
	color: #004380;
	letter-spacing: -0.1px;
}
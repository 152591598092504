.page__content {
    padding: 0;

    .content-big {
        height: auto;
        text-align: center;
        position: relative;

        h1 {
            font-family: $font-gotham-round-bold;
            position: absolute;
            opacity: 1;
            margin: 0 auto 0 auto;
            bottom: 4rem;
            z-index: 5;
            text-align: center;
            width: 100%;
            font-size: 1.875rem;

            @include media-breakpoint-up(md) {
                bottom: 5rem;
            }

            @include media-breakpoint-down(sm) {
                font-size: 1.6rem;
            }

            @include media-breakpoint-up(xl) {
                font-size: 3rem;
                bottom: 6rem;
            }
        }
    }

    picture {
        display: block;
        overflow: hidden;
        max-height: 300px;

        .home-banner-img {
            display: block;
            min-width: 100%;
            margin: 0 auto;
            left: 50%;
            transform: translate(-50%);
            position: relative;
            overflow: visible;
            z-index: 2;
            width: 120%;

            @include media-breakpoint-up(sm) {
                width: 100%;
            }

            @include media-breakpoint-up(md) {
                min-width: 100%;
                width: 1070px;
            }

            @include media-breakpoint-up(xl) {
                width: 1460px;
            }
        }
        @include media-breakpoint-up(sm) {
            max-height: 350px;
        }
        @include media-breakpoint-up(md) {
            max-height: 450px;
        }
        @include media-breakpoint-up(lg) {
            min-height: 23vw;
        }
    }
}

.cookie-consent {
  background-color: rgba(234,234,234,.9);
  border: 1px solid #666;
  bottom: 0;
  color: #666;
  font-family: $f-roboto-light;
  font-size: 10px;
  overflow: hidden;
  padding: 15px;
  position: fixed;
  width: 100%;
  z-index: 999;

  @include media-breakpoint-up(md) {
    font-size: 11px;
  }

  .cookie-consent--container {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;

    @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
      padding: 0;
      width: 680px;
    }

    @include media-breakpoint-up(lg) {
      width: 940px;
    }

    @include media-breakpoint-up(xl) {
      padding-left: 10px;
      padding-right: 10px;
      width: 1200px;
    }

    .item-text {
      align-items: center;
      display: flex;
      flex-basis: auto;

      a {
        display: none;

        @include media-breakpoint-up(md) {
          display: inline;
        }
      }

      @include media-breakpoint-up(md) {
        flex-basis: 100%;
      }
    }

    .item-link {
      align-items: center;
      display: flex;
      flex-basis: 60%;
      flex-shrink: 2;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    .item-button {
      button {
        background-color: rgb(102, 102, 102);
        border-radius: 0;
        color: #ffffff;
        float: right;
        font-size: 11px;
        line-height: 1.42857;
        padding: 8px 25px;
        border-width: 0;
      }

      @include media-breakpoint-up(md) {
        flex-basis: 30%;
        flex-shrink: 1;
        font-size: 11px;
      }
    }

    a {
      color: rgb(102, 102, 102);
      text-decoration: underline;
    }
  }
}
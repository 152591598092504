  .padding-form-container {
    padding: 0 4rem 2rem 4rem !important;
  }

  .hide-source {
    display: none;
  }

  .row {
    display: flex;
  }

  .column {
    width: 50%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .label-condition-form {
      display: inline;
      font-size: 12px;
      color: #fff;
  }


  .label-container {
    line-height: 13px !important;
    margin-bottom: 10px !important;
  }

  .label-color-form {
    color: #fff;
  }

  .modal-content-newsletter {
    width: 750px !important;
    left: -115px !important;
    background-color: #005b96;
    position: none;
    }

  .modal-title-center {
    line-height: 1.2 !important;
    text-align: center !important;
    font-size: 1.3rem !important;
    color: #fff;
    margin: 0 2rem 0 2rem;
    }

    #btn-form-newletter {
         background-color: #55B546 !important;
         border-color: #55B546 !important;
    }

    .message-success {
        text-align: center;
        color: #fff;
    }

    .adress-form-newsletter {
        color: #fff;
        font-size: 12px;
        line-height: 13px;
    }

    .errors-field-form {
          color: #fff;
          background-color: red;
          padding: 0 4px 0 4px;
          margin-top: 5px;
          display: inline;
          font-size: 12px;
      }

      .margin-checkbox-margin {
          margin-top: 8px;
      }

      .margin-errors-global-form {
        margin-left: 2.5rem;
      }

    @media (max-width: 768px) {
      .modal-content-newsletter {
          width: 700px !important;
          left: -98px !important;
       }

      .modal-title-center {
          line-height: 1.2 !important;
          font-size: 1.3rem !important;
          }
        }


    @media (max-width: 576px) {
       .modal-content-newsletter {
           width: 100% !important;
           left: 0px !important;
          }

       .modal-title-center {
           line-height: 1.2 !important;
           font-size: 1.1rem !important;
           margin: 0;
           }

        .padding-form-container {
           padding: 0 2rem 2rem 2rem !important;
           }

        .column {
             width: 100%;
         }

         .padding-mobile-field {
            padding-top: 1.5rem !important;
         }

         .margin-errors-global-form {
             margin-left: 0.5rem;
         }
        }
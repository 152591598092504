/*
* Featured Products
*/

.promo-container {
    background-color: white;
    box-shadow: 0px 5px 10px #16161514;
    position: relative;
    border-radius: 8px;
    padding: 16px 10px;
    z-index: 10;
    margin-top: -50px;

    @include media-breakpoint-up(sm) {
        margin-top: -50px;
    }

    @include media-breakpoint-up(md) {
        margin-top: -70px;
        padding: 46px 40px;
        width: 100%;
    }
}

.promo-products {
    padding: 0px;
    position: relative;
    text-align: center;
    top: 0;
    z-index: 2;

    @include media-breakpoint-up(md) {
        margin-top: 0;
    }
}

.promo-products__title {
    font-size: 40px;
    margin-top: 2rem;

    span {
        align-items: center;
        display: inline-flex;
        justify-content: center;
    }
}

.promo-products__list {
    list-style: none;
    margin: auto;
    padding: 0;

    &:not(.slick-initialized) {
        min-height: 210px;
        background-color: #f4f4f4;
        overflow: hidden;
        border-radius: 8px;

        .fp__list__item,
        img {
            display: none;
        }
    }
}

.fp__list__item {
    display: block;

    .card__image__mobile {
        display: none;
    }
}


@include media-breakpoint-down(sm) {
    .fp__list__item {

        .card__image {
            display: none;
        }

        .card__image__mobile {
            display: block !important;
            height: 100% !important;
            width: 100%;
            border-radius: 8px;
        }
    }

}

/* SLICK */

@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: './fonts/' !default;
$slick-font-family: 'slick' !default;
$slick-loader-path: './' !default;
$slick-arrow-color: white !default;
$slick-dot-color: $secondary !default;
$slick-dot-color-active: $primary !default;
$slick-prev-character: '\2190' !default;
$slick-next-character: '\2192' !default;
$slick-dot-character: '\2022' !default;
$slick-dot-size: 30px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {

    @if function-exists(image-url) {

        @return image-url($url);

    } @else {

        @return url($slick-loader-path + $url);

    }

}

@function slick-font-url($url) {

    @if function-exists(font-url) {

        @return font-url($url);

    } @else {

        @return url($slick-font-path + $url);

    }

}

/* Slider */

/*

.slick-list {

    .slick-loading & {
        background: #fff slick-image-url('ajax-loader.gif') center center no-repeat;

    }
}
*/

.promo-products__list.slick-slider .slick-list {

    .slick-slide {
        padding: 0 8px;

        @include media-breakpoint-up(lg) {
            padding: 0 12px;
        }

        img {
            max-width: 100%;
            width: 100%;
        }
    }
}

/* Icons */

@if $slick-font-family == 'slick' {

    @font-face {
        font-family: 'slick';
        font-style: normal;
        font-weight: normal;
        src: slick-font-url('slick.eot');
        src: slick-font-url('slick.eot?#iefix') format('embedded-opentype'), slick-font-url('slick.woff') format('woff'), slick-font-url('slick.ttf') format('truetype'), slick-font-url('slick.svg#slick') format('svg');
    }

}

/* Arrows */

.slick-prev,
.slick-next {
    background: transparent;
    border: none;
    color: transparent;
    cursor: pointer;
    display: block;
    font-size: 0px;
    height: 20px;
    line-height: 0px;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    width: 20px;

    &:hover, &:focus {
        background: transparent;
        color: transparent;
        outline: none;

        &::before {
            opacity: $slick-opacity-on-hover;
        }
    }

    &.slick-disabled::before {
        opacity: $slick-opacity-not-active;
    }

    &::before {
        color: $slick-arrow-color;
        font-family: $slick-font-family;
        font-size: 20px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        line-height: 1;
        opacity: $slick-opacity-default;
    }
}

.slick-prev {
    left: -40px;
    z-index: 10;

    /*[dir='rtl'] & {
        left: auto;
        right: -25px;
    }*/

    &::before {
        background: url('../img/icons/arrow-right-black.svg') center center no-repeat;
        content: '';
        display: block;
        height: 40px;
        width: 45px;
        transform: rotate(180deg);

        /*[dir='rtl'] & {
            background: url('../img/icons/icon-arrow-left.svg') center center no-repeat;
            content: '';
        }*/
    }
}

.slick-next {
    right: -15px;
    z-index: 10;

    &::before {
        background: url('../img/icons/arrow-right-black.svg') center center no-repeat;
        content: '';
        display: block;
        height: 40px;
        width: 45px;
    }
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 20px;
}

.slick-dots {
    bottom: -2rem;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    width: 100%;

    li {
        cursor: pointer;
        display: inline-block;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        position: relative;
        width: 20px;

        button {
            background: transparent;
            border: 0;
            color: transparent;
            cursor: pointer;
            display: block;
            font-size: 0;
            height: 20px;
            line-height: 0;
            outline: none;
            padding: 5px;
            width: 20px;

            &:hover, &:focus {
                outline: none;

                &::before {
                    opacity: $slick-opacity-on-hover;
                }
            }

            &::before {
                color: $slick-dot-color;
                content: $slick-dot-character;
                font-family: $slick-font-family;
                font-size: $slick-dot-size;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                height: 20px;
                left: 0;
                line-height: 20px;
                opacity: $slick-opacity-not-active;
                position: absolute;
                text-align: center;
                top: 0;
                width: 20px;
            }
        }

        &.slick-active button::before {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}

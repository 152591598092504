/*
    Site footer
*/

.site-footer {
  background: $white;
  position: relative;
  justify-self: flex-end;
  margin-top: auto;
}

.site-footer__inner {
  position: relative;
  padding-bottom: 30px;
  padding-top: 30px;
}

.footer__nav {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  width: 100%;
}

.btn-form-newsletter {
    font-size: 14px;
    margin-top: 0;
    @media (max-width: 991px) {
    margin-top: 2rem;
    }
}

.footer__nav__item {
  padding: 0.5rem 0;
  text-align: center;
  display: flex;
  align-items: center;

  &:not(:last-child):after {
    @include media-breakpoint-up(lg) {
      content: '';
      display: block;
      width: 1px;
      height: 10px;
      background-color: $secondary;
    }
  }

  .nav-link {
    color: #004380;
  }
}

.footer__nav__item__link {
  @include media-breakpoint-up(lg) {
    border-right: solid 1px $gray-500;
    padding-left: 1rem;
    padding-right: 1rem;

    .footer__nav__item:first-child & {
      padding-left: 0;
    }
  }
}

.copyright {
  color: #004380;
  text-align: center;

  @include media-breakpoint-down(lg) {
    font-size: 1rem;
    margin-right: 0;
    text-align: center;
    width: 100%;
  }
}
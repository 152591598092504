body {
    transition: background 1s ease-in-out;
}

.wrapper-content {
    text-align: center;

     p {
         margin: 0;
         text-align: center;
         font-size: 18px;
         line-height: 31px;
         letter-spacing: 0;
         color: #FFFFFF;
    }

    h1 {
        font-size: 80px;
        line-height: 110px;
        color: #0A2B6B;
        text-transform: uppercase;
        @include media-breakpoint-down(sm) {
            font-size: 65px;
            line-height: 92px;
        }
        span {
            text-align: center;
            font-size: 24px;
            letter-spacing: 0;
            line-height: 100%;
            color: #0A2B6B;
            text-transform: lowercase;
            display: block;
            font-family: 'DM sans', sans-serif;
            margin: 20px 0 30px 0;
        }
    }

    h2 {
        text-align: center;
        font-size: 60px;
        line-height: 103px;
        color: #0A2B6B;
        text-transform: uppercase;

        @include media-breakpoint-up(sm) {
            line-height: 100%;
            font-size: 50px;
            line-height: 93px;

        }
    }

    h3 {
        text-align: left;
        font-size: 29px;
        line-height: 30px;
    }
    .product-shoot{
        margin-top: 40px;
        max-width: 695px;
        width: 90%;
    }
}

.wrapper-tiles {
    width: 100%;
    max-width: 1099px;
    margin: 40px 0;
    @include media-breakpoint-down(md) {
        padding: 15px;
        margin: 10px 0;
        background: none;
        background-size: contain;
    }

}

.wrapper-tiles--irresistibles {
    background: #ffffff url('/dist/img/marques-privees/bg-desktop-irresistibles.jpg') no-repeat center center;
    background-size: cover;
    .wrapper-tiles__content__packshot {
        @include media-breakpoint-down(md) {
            background: #ffffff url('/dist/img/marques-privees/bg-mobile-irresistibles.jpg') no-repeat center center;
            padding-top: 69%;
            background-size: cover;
        }
    }
}

.wrapper-tiles--selection {
    background: #ffffff url('/dist/img/marques-privees/bg-desktop-selection.jpg') no-repeat center center;
    background-size: cover;
    .wrapper-tiles__content__packshot {
        @include media-breakpoint-down(md) {
            background: #ffffff url('/dist/img/marques-privees/bg-mobile-selection.jpg') no-repeat center center;
            padding-top: 69%;
            background-size: cover;
        }
    }
}

.wrapper-tiles__content {
    display: flex;
    justify-content: center;
    justify-items: center;
    padding-top: 500px;
    @include media-breakpoint-down(md) {
        flex-direction: column;
        flex-flow: column-reverse;
        padding-top: 0;
    }

    p {
        text-align: left;
        font: normal normal normal 18px/24px Roboto;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0;
        color: #000000;
        &:first-of-type {
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    >* {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        justify-items: center;
        width: 50%;
        flex-direction: column;
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
}

html[lang="fr"] {
    .wrapper-tiles--lifesmart {
        .wrapper-tiles__content__packshot {
            min-height: 775px;
            @include media-breakpoint-up(xl) {
                min-height: 777px;
            }
        }
    }
}

html[lang="en"] {
    .wrapper-tiles--lifesmart {
        .wrapper-tiles__content__packshot {
            min-height: 735px;
            @include media-breakpoint-up(xl) {
                min-height: 752px;
            }
        }
    }
}

.wrapper-tiles--lifesmart {
    .wrapper-tiles__content {
        max-width: unset !important;
        padding: 0;
        align-items: stretch;
        @include media-breakpoint-down(md) {
                align-items: unset;
        }
    }

    .wrapper-tiles__content__packshot {
        background: url('/dist/img/marques-privees/img-ls-famille.jpg') no-repeat center center;
        background-size: cover;
        padding-top: 52%;
        min-height: 730px;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}
.wrapper-tiles__content__text {
    padding: 40px;
    background: #FFFFFF;
    justify-content: flex-start;
    width: 100%;
}
.wrapper-tiles__content__packshot {
    padding: 40px;
    justify-content: flex-start;
    position: relative;
    width: 100%;

    >* {
        margin: 0 auto;
    }

    >img {
        max-width: 500px;
        width: 100%;
    }
}

.wrapper-tiles__content--lifesmart__left {
    width: 50%;
    @include media-breakpoint-down(md) {
        width: 100%;
    }
}

.wrapper-tiles__content--lifesmart__right {
    width: 50%;
    justify-content: stretch;
    @include media-breakpoint-down(md) {
        justify-content: flex-start;
        width: 100%;
    }
    .top-image{
        max-height: 400px;
        max-width: 710px;
        object-fit: cover;
    }
}
.bottom-tile-redirect{
    color: #fff;
}
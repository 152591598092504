.find-store-container {
    margin: 24px auto 32px auto;
    background-image: url("/dist/img/shapes/back-multicouleur-mobile.svg");
    border-radius: 8px;
    background-size: cover;
    //width: calc(100% - 30px);

    @include media-breakpoint-up(md) {
        background-image: url("/dist/img/shapes/back-multicouleur.svg");

    }

    h2 {
        font-family: $font-gotham-round-bold;
        color: $Endeavour;
        margin-bottom: 20px;
        font-size: 22px;
    }

    a {
        color: white;
        background-color: $Endeavour;
        padding: 12px 22px;
        border-radius: 20px;
        line-height: 1;
        font-size: 15px;
        font-family: $font-gotham-round-book;

        &:hover,
        &:focus {
            color: #212529;
            background-color: #f8f9fa;
        }
    }

    .fs_bg {
        max-width: 100%;
        position: relative;
        z-index: 2;
        border-radius: 12px;
    }
    .fs_deco {
        display: none;
        position: absolute;
        z-index: 0;
        @include media-breakpoint-up(xl) {
            display: block;
            right: -227px;
            bottom: 85%;
            width: 431px;
        }

    }
}

.fs-corner-deco {
    display: none;
    @include media-breakpoint-up(xl) {
        position: absolute;
        z-index: 0;
        display: block;
        top: -30px;
        left: 0;
        width: 82px;
        height: auto;
    }
}